<template>
  <div>
    <CoreAppBar v-model="expandOnHover" />

    <CoreDrawer :expand-on-hover.sync="expandOnHover" />

    <CoreLatestRelease />
    <CoreTermsAndConditions />

    <CoreHelpMenu />

    <CoreView />
  </div>
</template>

<script>
export default {
  name: 'CoreIndex',

  components: {
    CoreAppBar: () => import('./CoreAppBar'),
    CoreDrawer: () => import('./CoreDrawer'),
    CoreView: () => import('./CoreView'),
    CoreLatestRelease: () => import('./CoreLatestRelease'),
    CoreTermsAndConditions: () => import('./CoreTermsAndConditions'),
    CoreHelpMenu: () => import('./CoreHelpMenu')
  },

  data: () => ({
    expandOnHover: false
  })
}
</script>
