var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CoreAppBar", {
        model: {
          value: _vm.expandOnHover,
          callback: function($$v) {
            _vm.expandOnHover = $$v
          },
          expression: "expandOnHover"
        }
      }),
      _c("CoreDrawer", {
        attrs: { "expand-on-hover": _vm.expandOnHover },
        on: {
          "update:expandOnHover": function($event) {
            _vm.expandOnHover = $event
          },
          "update:expand-on-hover": function($event) {
            _vm.expandOnHover = $event
          }
        }
      }),
      _c("CoreLatestRelease"),
      _c("CoreTermsAndConditions"),
      _c("CoreHelpMenu"),
      _c("CoreView")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }